import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styles from './Hero.module.scss';

const Hero = (): React.ReactElement => {
  return (
    <section id="home" className="page_slider overflow-visible">
      <div className="flexslider">
        <ul className="slides">
          <li className={styles.background + ' cs cover-image text-center text-md-left'}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="intro_layers_wrapper">
                    <div className="img-layer animate" data-animation="fadeInLeft">
                      <img className={styles.tablet} src="images/ntc_iPad.png" alt="" />
                    </div>
                    <div className="intro_layers">
                      <div className="intro_layer" data-animation="fadeInRight">
                        <h1>NTC Method</h1>
                      </div>
                      <div className="intro_layer" data-animation="fadeInRight">
                        <p>
                          O livro que possibilita uma avaliação pessoal ou coletiva com uma
                          profundidade única e inigualável na sua simplicidade e objetividade.
                        </p>
                      </div>
                      <div className="intro_layer several-buttons" data-animation="fadeInUp">
                        <AnchorLink href="#about-page" className={styles.link}>
                          <button className="btn btn-maincolor">Saber Mais</button>
                        </AnchorLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Hero;
