import React from 'react';
import styles from './Footer.module.scss';

const Footer = (): React.ReactElement => {
  return (
    <footer className={styles.wrapper + ' page_copyright ls s-py-20 s-py-xl-50'}>
      <div className="container">
        <div className={styles.container + ' align-items-center'}>
          <div className=" text-center color-dark">
            <p>
              © Copyright <span className="copyright_year">2020</span> | Todos os direitos
              reservados
            </p>
          </div>
          <div className=" text-center color-dark">
            <p>
              Powered by{' '}
              <a target="_blank" href="https://moscadigital.pt/?utm_source=ntcmethod.pt" rel="noopener noreferrer">
                Mosca Digital
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
