import React from 'react';

import styles from './CallToAction.module.scss';

const CallToAction = (): React.ReactElement => {
  return (
    <section id="callToAction" className={styles.wrapper + ' ls ms s-py-90 s-py-xl-150'}>
      <div className={styles.container + ' container'}>
        <h4 className={styles.title + ' special-heading'}>
          <span>Descodifique os padrões da mecânica da vida e atinja o sucesso. Hoje.</span>
        </h4>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.amazon.com/NCTologia-Portuguese-Daniel-Fernandes-ebook/dp/B08TKR9HP6/ref=sr_1_1?dchild=1&keywords=nctologia&qid=1611259125&sr=8-1"
          className={styles.button + ' btn btn-maincolor'}
        >
          Comprar
        </a>
      </div>
    </section>
  );
};

export default CallToAction;
