import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const About = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      ntcGatsbyImage: file(relativePath: { eq: "ntcmethod.png" }) {
        childImageSharp {
          fluid(maxWidth: 780) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  return (
    <section id="about" className="ls ms s-py-90 s-py-xl-160 c-gutter-100">
      <span id="about-page"></span>

      <div className="container">
        <div className="row align-center">
          <div className="col-lg-6">
            <div className="divider-60 divider-lg-0" />
            <h3 className="special-heading">
              <span>O NTC Method</span>
            </h3>
            <div className="divider-40" />
            <p className="paragraph">
              O NTC é um estudo desenvolvido e centrado na lógica matemática dos números que nos
              permite desvendar os padrões de um individuo, coletivo ou acontecimento. À medida que
              o mesmo foi sendo desenvolvido e amadurecido, tornou-se claro estar-se perante um
              método que estuda padronologia (comportamento de padrões).
            </p>
            <p className="paragraph">
              Através deste método o abstrato do invisível da consciência ganha uma lógica e uma
              razão, tornando-se possível fazer um estudo analítico e profundo sobre qualquer pessoa
              ou acontecimento. A lógica da observação exercida pela analista ao individuo
              consultado permite estabelecer uma compreensão profunda dos seus padrões e ou do
              social envolvido.
            </p>
            <p className="paragraph">
              O método possibilita uma avaliação pessoal ou coletiva com uma profundidade única,
              arriscando a dizer inigualável na sua simplicidade e objetividade. Permite ao
              consultor um acesso total da descodificação dos seus padrões pessoais ou coletivos,
              gerando uma importantíssima fonte de entendimento sobre si próprio, assim como
              possíveis correções a executar, mediante o seu desejo, tais como os de um coletivo
              perante um objetivo comum.
            </p>
            <p className="paragraph">
              NTC avalia os padrões pessoais ou coletivos expondo as suas fortificações e
              fragilidades, estas últimas anulando-as, possibilitando e estimulando o individuo ou
              coletivo a alcançar, através da padronologia, os objetivos que traçou para a sua vida
              ou empresa.
            </p>
          </div>
          <div className="col-lg-6">
            <div className="video-shortcode">
              <Img alt="" fluid={data.ntcGatsbyImage.childImageSharp.fluid}></Img>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
