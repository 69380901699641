import React from 'react';

import styles from './Testimonials.module.scss';

const Testimonials = (): React.ReactElement => {
  return (
    <section
      id="testimonials"
      className={styles.wrapper + ' ds section-testimonials s-py-90 s-py-xl-150'}
    >
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              className="owl-carousel owl-nav-bottom"
              data-responsive-lg={1}
              data-responsive-md={2}
              data-responsive-sm={1}
              data-responsive-xs={1}
              data-nav="true"
              data-margin={60}
              data-loop="true"
              data-autoplay="true"
            >
              <div className={styles.testimonial + ' vertical-item quote-item'}>
                <div className="item-content text-center">
                  <h4 className="mb-3">Maria Antónia Ramos</h4>
                  {/* <p className={styles.job + ' position'}></p> */}
                  <p className={styles.description}>
                    Muito interessante o método NTC! A explicação que faltava para um melhor
                    autoconhecimento e também para entendermos melhor as pessoas que nos rodeiam.
                    Números e padrões associados! As datas e o que nos mostram sobre personalidade e
                    comportamentos! Muito bom!
                  </p>
                </div>
              </div>
              <div className={styles.testimonial + ' vertical-item quote-item'}>
                <div className="item-content text-center">
                  <h4 className="mb-3">Rui Duarte</h4>
                  {/* <p className={styles.job + ' position'}></p> */}
                  <p className={styles.description}>
                    NTC Method para mim foi o despertar de uma luz sobre a sombra, Foi o percorrer
                    de um caminho diferente de todos os outros; Foi um levantar de nevoeiro nas
                    curvas mais densas e arriscadas da vida, o subtil descobrir de um ombro amigo
                    sempre presente; NTC Method para mim é a descoberta do meu caminho na voz dos
                    números desde o nascimento até à hora de toda uma vida.
                  </p>
                </div>
              </div>
              <div className={styles.testimonial + ' vertical-item quote-item'}>
                <div className="item-content text-center">
                  <h4 className="mb-3">Ana Teresa Carvalho</h4>
                  {/* <p className={styles.job + ' position'}></p> */}
                  <p className={styles.description}>
                    O estudo de NTC tem sido impressionante, e até confortavelmente assustador, no
                    imenso sentido que nos faz o 'retrato' resultante da interpretação dos padrões
                    de alguém e da sua vida. E, melhor ainda, é a oportunidade de transformar
                    positivamente algo em nós, ao proporcionar a tomada de consciência de aspectos
                    que ignorávamos, ou sequer imaginávamos, como também de possibilitar a correcção
                    de padrões desequilibrados.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
