import React, { useEffect } from 'react';

import '../styles/main.scss';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Author from '../components/Author';
import About from '../components/About';
import Testimonials from '../components/Testimonials';
import Synopsis from '../components/Synopsis';
import Applications from '../components/Applications';
import Footer from '../components/Footer';
import CallToAction from '../components/CallToAction';

import ogImage from '../images/og-image.png';

const IndexPage = (): React.ReactElement => {
  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line no-undef
    __main(jQuery);
  }, []);

  return (
    <Layout>
      <SEO
        title="NTC Method"
        description="O NTC é um método que estuda a padronologia (comportamento de padrões) e possibilita uma autoavaliação, sendo uma grande fonte de entendimento sobre si próprio"
        imageUrl={ogImage}
        url=""
      />
      <div id="canvas">
        <div id="box_wrapper">
          <Header></Header>
          <Hero></Hero>
          <About></About>
          <Author></Author>
          <Testimonials></Testimonials>
          <Synopsis></Synopsis>
          <Applications></Applications>
          <CallToAction></CallToAction>
          <Footer></Footer>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
