import React from 'react';


interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps): React.ReactElement => {
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};

export default Layout;

