import React from 'react';

const Synopsis = (): React.ReactElement => {
  return (
    <section
      id="synopsis"
      className="wrapper ls s-overlay mobile-overlay s-py-90 s-py-xl-150 section-book"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6 ml-auto">
            <div className="divider-xl-110" />
            <h3 className="special-heading">
              <span>Sinopse</span>
            </h3>
            <div className="divider-40" />
            <div className="scroll-block">
              <p className="first-letter">
                “A visão que tens do Universo não é uma questão de Consciência, mas sim de
                perspetiva da tua Consciência” (D.F)
              </p>
              <p>
                O método de leitura Nctologia® nasce baseado na inspiração dos recursos da
                simbologia do Tarô de Rider Waite e dos significados atribuídos aos números através
                da Numerologia.
              </p>
              <p>
                Por outro lado, nasce também de toda uma pesquisa aprofundada sobre o funcionamento
                padronizado, ou se preferir cíclico, do próprio Universo, tendo dado origem ao
                insight que levou à lógica criativa da Leitura Universal de Números. É sim uma
                Leitura de Padrão Universal.
              </p>
              <p>
                O método funciona utilizando os números das datas e/ou das horas, combinados com a
                energia das cartas do Tarô numa mistura alquímica que, depois de decifrada,
                proporciona a capacidade de analisar e encontrar entendimento sobre um determinado
                facto até então oculto.
              </p>
              <p>
                Permite-nos inclusive, realizar uma leitura profunda de qualquer pessoa ao nível
                psicológico e espiritual. A técnica evoluiu ao ponto de se conseguir uma avaliação
                profundíssima do Ser que está a ser observado.
              </p>
            </div>
            <div className="divider-xl-100" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Synopsis;
