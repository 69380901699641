import React from 'react';
import logo from '../images/logo.svg';

const Header = (): React.ReactElement => {
  return (
    <header className="page_header ls s-overlay s-py-10">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-2 col-lg-3 col-11">
            <a href="#home" className="logo">
              <img src={logo} alt="Logotipo NTC" />
            </a>
          </div>
          <div className="col-xl-8 col-lg-5 col-1">
            {/* main nav start */}
            <nav className="top-nav">
              <ul className="nav sf-menu">
                <li>
                  <a href="#about">Sobre</a>
                </li>
                <li>
                  <a href="#author">Autor</a>
                </li>
                <li>
                  <a href="#testimonials">Testemunhos</a>
                </li>
                <li>
                  <a href="#synopsis">Sinopse</a>
                </li>
                <li>
                  <a href="#applications">Aplicações</a>
                </li>
              </ul>
            </nav>
            {/* eof main nav */}
          </div>
          <div className="col-xl-2 col-lg-3 text-right d-none d-lg-block">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.amazon.com/NCTologia-Portuguese-Daniel-Fernandes-ebook/dp/B08TKR9HP6/ref=sr_1_1?dchild=1&keywords=nctologia&qid=1611259125&sr=8-1"
              className="btn btn-maincolor"
            >
              Comprar
            </a>
          </div>
        </div>
      </div>
      {/* header toggler */}
      <span className="toggle_menu">
        <span />
      </span>
    </header>
  );
};

export default Header;
