import React from 'react';

const Applications = (): React.ReactElement => {
  return (
    <section
      id="applications"
      className="wrapper ls ms s-overlay mobile-overlay s-py-90 s-py-xl-150 section-amazon"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="divider-xl-110" />
            <h3 className="special-heading">
              <span>Aplicações</span>
            </h3>
            <div className="divider-40" />
            <p className="excerpt">
              Imagine ter nas suas mãos a possibilidade de descodificar os padrões da mecânica vida.
              O que seria capaz de obter?
            </p>
            <div className="divider-40" />
            <p className="paragraph">
              Todo o Ciclo da Vida integra uma mecânica padronizada dentro de si. Nada acontece sem
              que um padrão interfira. A vida é um padrão de acontecimentos. O interior de cada ser
              humano integra um padrão de acontecimentos. Demos-lhe o nome de personalidade. Uma
              empresa é um padrão em funcionamento.
            </p>
            <p className="paragraph">
              E se lhe for dada a oportunidade de gerir esses mesmos padrões para alcançar o seu tão
              desejado sucesso?
            </p>
            <p className="paragraph">
              O método aplicado NTC tem a capacidade de descodificar qualquer padrão. Identificar
              padrões de fragilidade e ou nefastos que o podem prejudicar a si, um coletivo ou mesmo
              a sua empresa. Ser capaz de identificar, anular e corrigir é o que o NTC lhe oferece
              como garantia.
            </p>
            <p className="paragraph">
              Um estudo e analise aprofundada sobre os padrões: pessoais, coletivos ou empresariais,
              que o envolvem neste momento é o primeiro passo para o alcance de um sucesso ainda não
              atingido. O NTC oferece-lhe a possibilidade de ajustar os padrões pessoais e
              empresariais ao seu sonho e ao que quer alcançar. O sucesso garantido!
            </p>
            <div className="divider-xl-100" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Applications;
