import React from 'react';

const Author = (): React.ReactElement => {
  return (
    <section
      id="author"
      className="wrapper ls s-overlay mobile-overlay s-py-90 s-py-xl-50 section-about"
    >
      <div className="container">
        <div className="row authorContainer">
          <div className="col-lg-6">
            <div className="divider-xl-200" />
            <p className="mb-2 color-dark">Conheça o autor</p>
            <h2 className="special-heading">
              <span>Daniel Fernandes</span>
            </h2>
            <div className="divider-40" />
            <p className="excerpt">
              "Na natureza tudo é um padrão. Logo se eu compreender os padrões da natureza/vida,
              tudo pode ser realizado."
            </p>
            <div className="divider-40" />
            <p>
              Nascido na África do Sul, desde sempre sentiu atração pela área da espiritualidade e
              sempre se questionou acerca das razões pelas quais determinadas coisas aconteciam de
              determinada forma. Assim, desenvolveu uma abordagem espiritual que tem ajudado muitas
              pessoas a ultrapassarem os seus desafios ou, como o próprio o diz, a alinharem os seus
              padrões energéticos. Esta nova abordagem espiritual designa-se por NTC Method.
              Genericamente conjuga o Tarot e a Numeralogia, além de trabalhar também com a
              integração de diversas áreas que tem estudado, como é o caso da Filosofia Quântica.
            </p>
            <div className="divider-40" />
            <div className="columns-two">
              <a
                href="https://danielffernandes.pt/"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-maincolor"
              >
                Mais sobre o autor
              </a>
              <img src="images/signature.png" alt="" />
            </div>
            <div className="divider-xl-150" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Author;
